<template>
  <b-row>
    <b-col cols="6">
      <flat-pickr
        v-model="fromDate"
        :config="configFromdateTimePicker"
        class="form-control"
        placeholder="From Date"
        @on-change="onFromChange"
      />
    </b-col>
    <b-col cols="6">
      <flat-pickr
        v-model="toDate"
        :config="configTodateTimePicker"
        class="form-control"
        placeholder="To Date"
        @on-change="onToChange"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    BRow,
    BCol,
  },
  props: [
    'value',
  ],
  data() {
    return {
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {},
      configTodateTimePicker: {},
    }
  },
  mounted() {
    if (typeof this.value === 'object') {
      this.configFromdateTimePicker.maxDate = this.value.toDate
      this.configTodateTimePicker.minDate = this.value.fromDate
      this.fromDate = this.value.fromDate
      this.toDate = this.value.toDate
    }
  },
  methods: {
    onFromChange(selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, 'minDate', dateStr)
      this.$emit('input', {
        fromDate: this.fromDate,
        toDate: this.toDate,
      })
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, 'maxDate', dateStr)
      this.$emit('input', {
        fromDate: this.fromDate,
        toDate: this.toDate,
      })
    },
  },
}
</script>
