<template>
  <div
    id="filter"
    class="ps-filter-template"
  >
    <div
      v-if="isMounted"
      class="filter-container"
    >
      <div
        v-for="(item ,key) in filterSettings"
        :key="key"
        class="m-50"
        style="display: flex;"
      >

        <!-- GENERATE INPUT -->
        <b-form-group
          v-if="item.type === 'input'"
          :class="item.class ? item.class : 'mb-0'"
          :label="item.label ? item.label: ''"
          :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
          :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
          :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
          :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
          :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
          :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
          :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
          :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
          :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
          :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
          :style="item.style"
        >
          <b-form-input
            v-model="models[key]"
            :placeholder="item.placeHolder"
            @keypress.enter.exact="search()"
          />
        </b-form-group>

        <!-- GENERATE CHECKBOXES -->
        <b-form-group
          v-if="item.type === 'checkbox'"
          :label="item.label"
          :class="item.class ? item.class : 'mb-0'"
          :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
          :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
          :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
          :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
          :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
          :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
          :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
          :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
          :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
          :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
          :style="item.style"
        >
          <b-form-checkbox-group
            id="checkbox-group-2"
            v-model="models[key]"
            name="flavour-2"
            style="padding-top: 8px;"
          >
            <b-form-checkbox
              v-for="(itemData, index) in item.data"
              :key="index"
              :value="itemData.value"
            >
              {{ itemData.text }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>

        <!-- GENERATE SELECTBOX -->
        <b-form-group
          v-if="item.type === 'select'"
          :label="item.label"
          :class="item.class ? item.class : 'mb-0'"
          :style="item.style"
          :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
          :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
          :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
          :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
          :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
          :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
          :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
          :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
          :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
          :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
        >
          <b-form-select v-model="models[key]">
            <b-form-select-option
              v-for="(data, index) in item.data"
              :key="index"
              :value="data.value"
            >{{ data.text }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>

        <!-- GENERATE RADIO BUTTON -->
        <b-form-group
          v-if="item.type === 'radio'"
          :label="item.label"
          :class="item.class ? item.class : 'mb-0'"
          :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
          :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
          :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
          :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
          :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
          :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
          :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
          :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
          :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
          :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
          :style="item.style"
        >
          <b-form-radio-group
            v-model="models[key]"
            :name="key"
            @change="item.change ? item.change(models[key]) : null"
          >
            <b-form-radio
              v-for="(itemData, index) in item.data"
              :key="index"
              :value="itemData.value"
            >
              {{ itemData.text }}
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <!-- DATE -->
        <b-form-group
          v-if="item.type === 'date'"
          :label="item.label"
          :class="item.class ? item.class : 'mb-0'"
          :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
          :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
          :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
          :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
          :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
          :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
          :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
          :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
          :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
          :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
          :style="item.style"
        >
          <b-form-datepicker v-model="models[key]" />
        </b-form-group>

        <!-- DATE RANGE -->
        <b-form-group
          v-if="item.type === 'daterange'"
          :label="item.label"
          :class="item.class ? item.class : 'mb-0'"
          :label-cols="typeof item['label-cols'] === 'number' ? item['label-cols'] : null"
          :label-cols-sm="typeof item['label-cols-sm'] === 'number' ? item['label-cols-sm'] : null"
          :label-cols-md="typeof item['label-cols-md'] === 'number' ? item['label-cols-md'] : null"
          :label-cols-lg="typeof item['label-cols-lg'] === 'number' ? item['label-cols-lg'] : null"
          :label-cols-xl="typeof item['label-cols-xl'] === 'number' ? item['label-cols-xl'] : null"
          :content-cols="typeof item['content-cols'] === 'number' ? item['content-cols'] : null"
          :content-cols-sm="typeof item['content-cols-sm'] === 'number' ? item['content-cols-sm'] : null"
          :content-cols-md="typeof item['content-cols-md'] === 'number' ? item['content-cols-md'] : null"
          :content-cols-lg="typeof item['content-cols-lg'] === 'number' ? item['content-cols-lg'] : null"
          :content-cols-xl="typeof item['content-cols-xl'] === 'number' ? item['content-cols-xl'] : null"
          :style="item.style"
        >
          <date-range v-model="models[key]" />
        </b-form-group>

      </div>
      <b-button
        variant="primary"
        @click.prevent="search()"
      >
        Search
      </b-button>
      <b-button
        v-if="showExport"
        variant="success"
        @click.prevent="exportToCSV()"
      >
        CSV Export
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'
import DateRange from './DateRange.vue'

export default {
  components: {
    DateRange,
    BButton,
    BFormCheckbox,
    BFormRadio,
    BFormSelect,
    BFormSelectOption,
    BFormRadioGroup,
    BFormGroup,
    BFormCheckboxGroup,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    filterSettings: {
      type: Object,
      default() {
        return {}
      },
    },
    firstSearch: {
      type: Boolean,
      default: true,
    },
    showExport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      models: {},
      isMounted: false,
    }
  },
  created() {
    Object.keys(this.filterSettings).forEach(key => {
      this.models[key] = this.filterSettings[key].defaultValue
    })
  },
  mounted() {
    this.isMounted = true
    if (this.firstSearch) this.search()
  },
  methods: {
    search() {
      this.$emit('input', this.models)
      this.$emit('search')
    },
    exportToCSV() {
      this.$emit('input', this.models)
      this.$emit('exportToCSV')
    },
  },
}
</script>
<style scoped>
.ps-filter-template {
  margin-bottom: 10px;
}

.ps-filter-template .filter-container {
  display: table;
  width: 100%;
}

.ps-filter-template .filter-container > * {
  clear: none;
  float: left;
  margin-right: 10px;
}

.ps-filter-template .leftx {
  display: flex;
}

.ps-filter-template .leftx li {
  margin-right: 10px;
  font-size: 14px;
}

.ps-filter-template .input-select .vs-select--input {
  max-width: 150px;
}

.ps-filter-template #checkbox {
  margin-top: 10px;
}

div[role="radiogroup"] {
  margin-top: 5px;
}
</style>
